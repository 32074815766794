.btn-secondry,
.btn-danger,
.btn-primary,
.btn-light {
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}
.btn-primary {
    padding: 9px 20px;
    color: white;
    background-color: $endeavour;
    box-shadow: 0 3px 7px 0 rgba($endeavour, 0.4);

    &:hover {
        box-shadow: 0 3px 7px 0 rgba($endeavour, 0.4);
    }

    &:focus {
        box-shadow: 0 3px 7px 0 rgba($endeavour, 0.4);

        &:hover {
            box-shadow: 0 3px 7px 0 rgba($endeavour, 0.4)
        }
    }

    &:not(:disabled) {
        &:not(.disabled) {
            &:active {
                box-shadow: 0 3px 7px 0 rgba($endeavour, 0.4);

                &:focus {
                    box-shadow: 0 3px 7px 0 rgba($endeavour, 0.4)
                }
            }
        }
    }
}
.login-btn {
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    top: 30px;
    right: 40px;
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
        color: white;
        font-weight: 600;
        text-decoration: none;
        &:before {
            margin-right: 7px;
            padding: 6px 5px 5px 5px;
            font-size: 12px;
            border: 1px solid white;
            border-radius: 100%;
            color: rgba($mine-shaft-color, 0.8);
            background-color: white;

        }
        span {
            font-family: $font-default;
        }
    }
}