$icomoon-font-family: "HCR" !default;
$icomoon-font-path: "../fonts" !default;


$icon-info: "\e915";
$icon-enter: "\e914";
$icon-loop: "\e913";
$icon-bin: "\e90d";
$icon-checkmark: "\e910";
$icon-pencil: "\e911";
$icon-user: "\e912";
$icon-arrow: "\e905";
$icon-map-pin-touch-path1: "\e906";
$icon-map-pin-touch-path2: "\e908";
$icon-map-pin-touch-path3: "\e909";
$icon-directions: "\e90b";
$icon-ic-save-alt-24-px: "\e90c";
$icon-save: "\e907";
$icon-star: "\e90e";
$icon-filter-active: "\e90f";
$icon-map-pin: "\e90a";
$icon-menu: "\e9bd";
$icon-email: "\e904";
$icon-asterisk: "\e903";
$icon-twitter: "\e900";
$icon-linkedin: "\e901";
$icon-facebook: "\e902";
$icon-search: "\e986";
$icon-plus: "\ea0a";
$icon-minus: "\ea0b";
$icon-loader: "\e916";





@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?5k02vh') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?5k02vh') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?5k02vh##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-enter {
  &:before {
    content: $icon-enter; 
  }
}
.icon-loop {
  &:before {
    content: $icon-loop; 
  }
}
.icon-bin {
  &:before {
    content: $icon-bin; 
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-arrow {
  &:before {
    content: $icon-arrow; 
  }
}
.icon-map-pin-touch .path1 {
  &:before {
    content: $icon-map-pin-touch-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-map-pin-touch .path2 {
  &:before {
    content: $icon-map-pin-touch-path2;  
    margin-left: -0.7041015625em;  
    color: rgb(109, 192, 101);
  }
}
.icon-map-pin-touch .path3 {
  &:before {
    content: $icon-map-pin-touch-path3;  
    margin-left: -0.7041015625em;  
    color: rgb(109, 192, 101);
  }
}
.icon-directions {
  &:before {
    content: $icon-directions; 
  }
}
.icon-ic-save-alt-24-px {
  &:before {
    content: $icon-ic-save-alt-24-px; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-filter-active {
  &:before {
    content: $icon-filter-active; 
  }
}
.icon-map-pin {
  &:before {
    content: $icon-map-pin; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-asterisk {
  &:before {
    content: $icon-asterisk; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-loader {
  &:before {
    content: $icon-loader; 
  }
}

