*,
*:focus {
  outline: none !important;
}
html,
body {
  height: 100%;
  font-size: 16px;
  font-family: $font-default;
}
.container {
  // border: 1px solid red;
  max-width: 1204px;
  @include mq("sm", max) {
    padding-left: 0;
    padding-right: 0;
  }
}
#root {
  height: 100%;
}
.wrapper {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}
.custom-tabs {
  @include mq("sm", max) {
    padding: 0 10px;
  }
  a {
    background-color: $catskill-white-color;
    padding: 9px 50px !important; 
  }
}

