.content-area {
    flex: 1;
    hr {
        margin-top: 0;
    }
    .checkbox-noti-wrapper {
        color: $mine-shaft-color;
        span {
            font-size: 14px;
            padding: 15px 5px;
            color:  $endeavour;
            font-weight: 900;
        }
    }
    .title {
        padding: 10px 0px 0px 0px;
        font-weight: 700;
        color:  $endeavour;
        font-size: 26px;
    }
    .progress  {
        .progress-bar {
            background-color: $endeavour;
        }
    }
    .wapper   {
        margin: 0 auto;
        width: 320px;
        max-width: 85%;
        padding: 50px 0;
        .flex-wrapper {
            .title {
                padding: 13px 0;
                font-size: 14px;
            }
            .input-container {
                width: 100%;
            }
        }
        .radio-btn-wrapper   {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .custom-control {
                width: 50%;
                margin-right: 0px;
                padding: 0;
                input:checked ~ .label1,
                input:checked ~ .label2 {
                    &::before {
                        background-color: $endeavour;
                    }
                }
                label {
                    padding: 5px 20px;
                    width: 100%;
                    margin: 0 2px;
                    text-align: center;
                    border-radius: 5px;
                    &::before {
                        position: absolute;
                        left: 9px;
                        top: 9px;
                    }
                }
                .label1 {
                    background-color: rgba($Eucalyptus-color, 0.5);
                    font-weight: 600;
                    color: $mine-shaft-color;

                    &::after {
                        top: 9px;
                        left: 9px;
                    }
                }
                .label2 {
                    background-color: rgba($punch-color, 0.5);
                    font-weight: 600;
                    color: $mine-shaft-color;
                    &::after {
                        top: 9px;
                        left: 9px;
                    }
                }
            }
        }
    }
    .upload-wrapper  {
        .dropdown-toggle {
            margin-bottom: 15px;
            width: 100%;
            padding: 9px;
            text-align: left;
            &::after {
                position: absolute;
                right: 12px;
                top: 16px;
            }
        }
        .ellipse {
            width: 100%;
            position: relative;
            padding-right: 90px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            input {
                position: fixed;
            }
        }
        .dropdown-menu {
            width: 100%;
            padding: 0;
            a {
                white-space: initial;
                transition: 100ms;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 15px;

                &:hover {
                    background-color: $endeavour;
                    color: white;
                }
            }
        }
    }
    .upload-btn-wrapper  {
        padding: 20px 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }


    // Login Form

    .login-form-container {
        width: 320px;
        max-width: 85%;
        margin: 0 auto;
        padding: 50px 0;

        .form-title {
            padding: 10px 0;
            font-weight: 900;
            color:  $endeavour;
            font-size: 25px;
            text-align: center;
        }
        .input-group {
            .form-control {
                &::placeholder {
                    color: rgba(black, 0.2);
                }
            }
        }
    }
    .form-upload-container {
        background-color: $Gallery;
        .upload-sec-head {
            color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .item-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                white-space: nowrap;
                a {
                    font-size: 14px;
                    padding: 15px 20px;
                    font-weight: 600;
                    margin: 0;
                    color: $endeavour;
                    cursor: pointer;
                    &:hover {
                        text-decoration: none;
                    }

                }
                .active {
                    background-color: $endeavour;
                    color: $bianca-color;
                }
            }
            .logout-wrapper {
                @include mq("sm", max) {
                    display: none;
                }
            }
        }
    }

    // 



    .notification-area {
        background-color: $catskill-white-color;
        padding: 15px 0;
        p {
            margin: 0;
            color: $maroon-flush-color;
            font-size: 14px;
            text-align: center;
            font-weight: 600;
            overflow-wrap: break-word;
            padding: 1px;
            @include mq("sm", max) {
                padding: 0 10px;
            }
        }
    }
}






// humbergr css
.humburger {
    position: fixed;
    z-index: 1000;
    cursor: pointer;
    top: 20px;
    right: 23px;
    background-color: $Gallery;
    padding: 10px;
    border-radius: 50%;
    color:  $endeavour;
    -webkit-box-shadow: 0px 0px 13px -7px rgba(79,77,79,1);
    -moz-box-shadow: 0px 0px 13px -7px rgba(79,77,79,1);
    box-shadow: 0px 0px 13px -7px rgba(79,77,79,1);
    .list {
        list-style-type: none;
        padding: 0;
        white-space: nowrap;
    }
    .d-none {
        display: block !important;
    }
    .logout-text {
        z-index: 10000;
        width: 245px;
        display: none;
        position: absolute;
        left: -215px;
        top: 40px;
        border-radius: 2px;
        background-color: $Gallery;
        -webkit-box-shadow: 0px 0px 13px -7px rgba(79,77,79,1);
        -moz-box-shadow: 0px 0px 13px -7px rgba(79,77,79,1);
        box-shadow: 0px 0px 13px -7px rgba(79,77,79,1);
        .user-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 20px !important;
            flex-direction: column;
            border-bottom : 1px solid rgba($endeavour, 0.5);
            &:hover {
                background-color: $Gallery !important;
                color: $endeavour !important;
            }

            .icon-user {
                font-size: 20px;
                border: 1px solid $dusty-gray;
                border-radius: 100%;
                padding: 12px;
            }
        }
        .logout {
            color:  $endeavour;
            font-family: $font-default;
            font-size: 14px;
            font-weight: 600;
            margin: 0;
            padding: 10px 20px;
            transition: 50ms;
            cursor: pointer;
            &:hover {
                background-color: $endeavour;
                color: white;
            }
            .user-name {
                white-space: normal;
            }
        }

    }
}



.rt-noData {
    top: 135%!important;
    background: none !important;
}


.content-head {
    align-items: center;
    .head-img-wrapper  {
        img {
            width: 305px;
            @include mq("lg", max) {
                width: 205px;
                height: 170px;
            }
        }
        @include mq("md", max) {
            display: none;
        }
    }
    .head-title-wrapper {
        padding: 10px 55px;
        @include mq("lg", max) {
            padding: 10px 100px 10px 20px;
        }
        @include mq("md", max) {
            padding: 10px 18px;
        }
        @include mq("xs", max) {
            padding: 10px 15px;
        }
        @include mq("sm", max) {
            padding: 10px;
        }
        p {
            margin: 0;
            color: $tundora-color;
        }
    }
}

.file-download {
    color: cornflowerblue;
    a {
        color: #17a2b8;
    }
}

.c-pointer {
    cursor: pointer !important;
}
.relative-wrap {
    position: relative;
    &:hover {
        background-color: $bianca-color !important;
    }
    span {
        position: absolute;
        right: 13px;
        top: 7px;
        font-weight: 600;
        font-size: 12px;
        color: $dusty-gray;
    }
}
.map-wrapper {
    position: relative;
    .dropdown-wrapper {
        position: absolute;
        left: 13px;
        top: 100px;
        min-width: 20%;
        z-index: 1;
        @include mq("sm", max) {
            left: 20px;
        }
        .icon-search {
            position: absolute;
            left: 0;
            top: 3px;
            padding: 10px;
            color: rgba($dusty-gray, 0.8);
        }
        .other-icons  {
            position: absolute;
            right: -1px;
            top: 3px;
            padding: 8px 0;
            .icon-star {
                color: $dusty-gray;
                font-size: 18px;
                &.active-clr {
                    color:  $endeavour;
                    pointer-events: auto;
                }
                &.disabled {
                    pointer-events: none;
                }
            }
            .icon-filter-active {
                color: $endeavour;
                font-size: 18px;
                &::before {
                    &.active {
                        background-color: $endeavour;
                        color: white;
                    }
                }
            }
            span {
                padding: 11px 12px;
                &.active {
                    background-color: $endeavour;
                    color: white;
                    &::before {
                        color: white;
                    }
                }
            }
        }
        .search-input {
            background-color: rgba($bianca-color, 0.7);
            width: 100%;
            padding: 8px 95px 8px 40px;
            border: none;
            font-size: 16px;
            font-weight: 500;
            &::before {
                font-family: '#{$icomoon-font-family}';
                content: $icon-search;
            }
        }


        .accordion {
            .card-header,
            .card-body {
                padding: 0;
            }
            .card-header {
                .card-header {
                    font-size: 14px;
                    padding: 4px 15px;
                    font-weight: 500;
                    color: $endeavour;
                    span {
                        font-family: $font-default;
                        font-size: 14px;
                        font-weight: 500;
                        color: $endeavour;
                        position: absolute;
                        right: 10px;
                        top: 7px;
                        transform: rotate(90deg);
                        &.rotate-arrow {
                            transition: 100ms;
                            transform: rotate(-90deg);
                        }
                    }
                }
            }
        }
        .search-dropdown {
            background-color: $bianca-color;
            padding: 0px 0px 15px 0px;
            margin-bottom: 0;
            list-style-type: none;
            .filter-dropdown {
                list-style-type: none;
                padding: 0;
            }
            .drop-arrow {
                position: relative;
                padding: 10px 15px !important;
                border-bottom: 1px solid rgba($dusty-gray, 0.2);
                &::before {
                    color: $dusty-gray;
                    position: absolute;
                    right: 15px;
                    top: 10px;
                    transform: rotate(90deg);
                }
                span {
                    font-family: $font-default;
                    font-size: 15px;
                    font-weight: 500;
                    color: $endeavour;
                }
            }
            .d-tab-wrapper {
                .dropdown-tabs {
                    border-top: 1px solid rgba($dusty-gray, 0.2);
                    border-bottom: 1px solid rgba($dusty-gray, 0.2);
                    padding: 10px 0px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .tab-text {
                        font-family: $font-default;
                        pointer-events: auto;
                    }
                    .disabled {
                        pointer-events: none;
                    }
                    .icon-ic-save-alt-24-px {
                        width: calc(100% / 2);
                        text-align: center;
                        font-size: 14px;
                        color: $endeavour;
                        font-weight: 500;
                        padding: 0px 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include mq("sm", max) {
                            font-size: 12px;
                        }
                        &::before {
                            font-size: 14px;
                            margin-right: 7px;
                        }
                    }
                    .icon-directions {
                        width: calc(100% / 2);
                        text-align: center;
                        font-size: 14px;
                        padding: 0px 5px;
                        color: $endeavour;
                        font-weight: 500;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include mq("sm", max) {
                            font-size: 12px;
                        }
                        &::before {
                            font-size: 20px;
                            margin-right: 6px;
                        }
                    }
                }
            }
            .address {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                span {
                    font-size: 12px;
                    padding: 6px 20px;
                    color: $mine-shaft-color;
                }
            }
            .wrap {
                padding: 15px 20px;
                border-top: 1px solid rgba($dusty-gray, 0.2);
                &:hover {
                    background-color: rgba($wild-blue-yonder-color, 0.1);
                }

                .red-spot {
                    padding: 5px;
                    border-radius: 50%;
                    background-color: $brick-red-color;
                    margin-right: 10px;
                    width: 11px;
                    height: 11px;
                }
                .green-spot {
                    padding: 5px;
                    border-radius: 50%;
                    background-color: $de-york;
                    margin-right: 10px;
                    width: 11px;
                    height: 11px;
                }
                .yellow-spot {
                    padding: 5px;
                    border-radius: 50%;
                    background-color: $dolly-color;
                    margin-right: 10px;
                    width: 11px;
                    height: 11px;
                }
                .orange-spot {
                    padding: 5px;
                    border-radius: 50%;
                    background-color: $orange-pee-color;
                    margin-right: 10px;
                    width: 11px;
                    height: 11px;
                }
                .white-spot {
                    padding: 5px;
                    border-radius: 50%;
                    background-color: #ffffff;
                    margin-right: 10px;
                    width: 11px;
                    height: 11px;
                }
                .black-spot {
                    padding: 5px;
                    border-radius: 50%;
                    background-color: $mine-shaft-color;
                    margin-right: 10px;
                    width: 11px;
                    height: 11px;
                }
                .light-blue-spot{
                    padding: 5px;
                    border-radius: 50%;
                    background-color: $wild-blue-yonder-color;
                    margin-right: 10px;
                    width: 11px;
                    height: 11px;
                }
                .item {
                    margin-top: -3px;
                    h6 {
                        font-size: 15px;
                        font-weight: 500;
                        margin: 0 0 2px 0;
                        color: $tundora-color;
                    }
                    p {
                        margin: 0;
                        font-size: 12px;
                        color: $dusty-gray;
                    }
                }
            }

            .d-title {
                font-size: 14px;
                font-weight: 700;
                padding: 6px 15px;
            }
            .item-wrapper {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: baseline;
                font-size: 14px;
                padding: 3px 15px;

                .status-text {
                    width: 75%;
                }
                .cbox {
                    height: 15px;
                    width: 15px;
                    margin: 0px 10px 0px 0px;
                    position: relative;
                    top: 3px;
                    opacity: 0.7;
                    transform: scale(1.1);
                    &:checked {
                        background-color: aqua;
                    }
                }
                .green {
                    padding: 5px;
                    border-radius: 50%;
                    background-color: $de-york;
                    margin-right: 8px;
                }
                .red {
                    padding: 5px;
                    border-radius: 50%;
                    background-color: $brick-red-color;
                    margin-right: 8px;
                }
                .yellow {
                    padding: 5px;
                    border-radius: 50%;
                    background-color: $dolly-color;
                    margin-right: 8px;
                }
                .orange {
                    padding: 5px;
                    border-radius: 50%;
                    background-color: $orange-pee-color;
                    margin-right: 8px;
                }
                .white {
                    padding: 5px;
                    border-radius: 50%;
                    background-color: #ffffff;
                    margin-right: 8px;
                }
                .counting {
                    position: absolute;
                    right: 10px;
                    top: 3px;
                    font-size: 14px;
                    color: $dusty-gray;
                }
            }

        }

        .disabled {
            pointer-events: none;
        }

        .popup-direction-detail {
            background-color: white;
            padding: 10px 20px;
            h6 {
                color: $endeavour;
                font-size: 15px;
                font-weight: 500;
            }
            span {
                font-size: 12px;
                display: block;
                color: $tundora-color;
            }
        }


    }


    .map-status-wrapper  {
        position: absolute;
        left: 0px;
        bottom: 0;
        min-width: 18%;
        z-index: 1;
        @include mq("sm", max) {
            left: 10px;
        }
        .map-status-items {
            background-color: rgba($bianca-color, 0.9);
            padding: 15px 8px;
            margin: 0;
            list-style-type: none;
            li {
                padding: 0px 3px;
                font-size: 14px;
                color: $tundora-color;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                .icon-map-pin-touch {
                    margin-right: 11px;
                    font-size: 20px;
                    position: relative;
                    top: -3px;
                    padding: 4px 0px;
                }
                &.i-open {
                    .path1 {
                        &::before {
                            color: $de-york;
                        }
                    }
                    .path2 {
                        &::before {
                            color: black;
                        }
                    }
                    .path3 {
                        &::before {
                            color: black;
                        }
                    }

                }
                &.i-not-participating {
                    .path1 {
                        &::before {
                            color: white;
                        }
                    }
                    .path2 {
                        &::before {
                            color: black;
                        }
                    }
                    .path3 {
                        &::before {
                            color: black;
                        }
                    }
                }
                &.i-reported {
                    .path1 {
                        &::before {
                            color: $dolly-color;
                        }
                    }
                    .path2 {
                        &::before {
                            color: black;
                        }
                    }
                    .path3 {
                        &::before {
                            color: black;
                        }
                    }
                }
                &.i-unknown {
                    .path1 {
                        &::before {
                            color: $brick-red-color;
                        }
                    }
                    .path2 {
                        &::before {
                            color: black;
                        }
                    }
                    .path3 {
                        &::before {
                            color: black;
                        }
                    }
                }
                &.counti-color-participating {
                    .path1 {
                        &::before {
                            color: $orange-pee-color;
                        }
                    }
                    .path2 {
                        &::before {
                            color: black;
                        }
                    }
                    .path3 {
                        &::before {
                            color: black;
                        }
                    }

                }
                &.counti-color-reported {
                    .path1 {
                        &::before {
                            color: $candy-corn-color;
                        }
                    }
                    .path2 {
                        &::before {
                            color: black;
                        }
                    }
                    .path3 {
                        &::before {
                            color: black;
                        }
                    }
                }
                &.counti-color-unknown {
                    .path1 {
                        &::before {
                            color: $Persimmon-color;
                        }
                    }
                    .path2 {
                        &::before {
                            color: black;
                        }
                    }
                    .path3 {
                        &::before {
                            color: black;
                        }
                    }
                }
            }
        }
    }
    .map {
        height: 700px;
        @include mq("sm", max) {
            padding: 0 10px;
        }
        .gm-style-mtc {
            div {
                padding: 0 !important;
                width: 100px;
                div {
                    span {
                        width: 200px;
                        padding: 0 !important;
                        img {
                            transform: translateY(0)!important;
                            height: auto !important;
                            width: auto !important;
                        }
                    }
                    label {
                        padding: 10px!important;
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
        input[type=text] {
            top: 15% !important;
            left: 12px !important;
            margin-left: 0 !important;
            margin-top: 0 !important;
        }
        img {
            height: 100%;
            width: 100%;
        }
    }
}

.no-record {
    padding: 10px 15px;
    color: $scorpion-color;
    font-weight: 500;
}
.w-70 {
    width: 70% !important;
}
.b-border {
    border: 1px solid black;
}

.about-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
    @include mq("lg", max) {
        justify-content: center;
    }
    .about-text {
        width: calc(100% / 2 + 136px);
        color: $tundora-color;
        @include mq("lg", max) {
            width: auto;
        }
        a {
            color: $endeavour;
            font-weight: 600;
        }
        @include mq("lg", max) {
            padding: 10px;
        }
    }
    .about-logos {
        text-align: right;
        white-space: nowrap;
        .logo1 {
            height: 66px;
            margin-right: 50px;
            @include mq("sm", max) {
                margin-right: 0;
            }
        }
        .logo2 {
            height: 66px;
        }
        img {
            @include mq("sm", max) {
                margin: 10px;
            }
        }
        @include mq("lg", max) {
            padding: 15px 0px;
        }
        @include mq("sm", max) {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-wrap: wrap;
            white-space: pre-wrap;
        }
    }
}

.scroll {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}

.save-list-item {
    position: relative;
    padding: 8px 20px 6px 20px !important;
}

.download-wrapper {
    @include mq("lg", max) {
        text-align: center;
    }
    button {
        padding: 9px 56px;
        border-radius: 0;
    }
}
.truncate {
    width: 205px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-table {
    max-width: 100%;
    min-width: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
}
.truncate-full {
    width: 288px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hover-bin {
    color: rgba(gray, 0.8);
    &:hover {
        color:  rgba(red, 0.8);
    }
}


.d-flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.add-user-btn {
    white-space: nowrap;
    &:before {
        margin-right: 6px;
    }
    span {
        font-family: $font-default;
    }
}
.theah-silver {
    background-color: $silver;
}
.custom-class {
    .page-item {
        &.active {
            a {
                background-color: $endeavour !important;
            }
        }
    }
}
.table-scroll {
    width: 100%;
    overflow: auto;
}
.w-10 {
    width: 10%;
}
.w-25 {
    width: 25%;
}
.user-icons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.forgot-password {
    margin: 4px 3px 0px 0px;
    font-size: 12px;
    a {
        color: rgba($endeavour, 0.8);
        &:hover {
            text-decoration: none;
            color: rgba($endeavour, 0.8);
        }
    }
}

.reset-password {
    a {
        color: rgba($endeavour, 0.8);
        &:hover {
            text-decoration: none;
            color: rgba($endeavour, 0.8);
        }
    }
}

.clear-all {
    padding: 0px !important;;
    margin-right: 4px;
    font-size: 12px;
    color: rgba($endeavour, 0.8);
}
.box-shadow-0 {
    box-shadow: none !important;
}
.text-deco-none {
    &:hover {
        text-decoration: none;
    }
}
.no-data {
    text-align: center;
    font-size: 20px;
    padding: 15px 0;
    color: $dusty-gray;
}

.note {
    font-size: 16px;
    padding: 15px 0;
}
.cross {
    position: absolute;
    right: 14px;
    top: 17px;
    font-size: 13px;
}
.red-starick {
    font-size: 19px;
    top: .0em;
    color: red;
}
.loader-wrapper {
    background-color: rgba($catskill-white-color ,0.9);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    img {
        height: 150px;
    }
}
