header {
    background-image: url("../images/header.png");
    background-repeat: no-repeat;
    background-size: cover;
    .header-overlay {
        padding: 40px 0;

        .logo-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include mq("lg", max) {
                flex-wrap: wrap;
                justify-content: center;
                flex-direction: column;
            }

            .main-logo-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;

                @include mq("lg", max) {
                    justify-content: center;
                    flex-direction: column;
                }
                .rx-logo {
                    height: 122px;
                }
                span {
                    font-size: 14px;
                    color: $bianca-color;
                    padding: 0 20px;
                    margin-top: 70px;
                    text-align: center;
                    text-transform: uppercase;
                    @include mq("sm", max) {
                        font-size: 12px;
                    }
                    @include mq("lg", max) {
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                }
            }
            .logo-other {
                height: 50px;
            }
        }
    }
}
