.nav-tabs {
    background-color: $catskill-white-color;
    border: none;
    .nav-link {
        border: none;
        border-radius: 0;
        font-size: 14px;
        font-weight: 600;
        color: $endeavour;
        text-transform: uppercase;
        padding: 15px 20px;
        @include mq("sm", max) {
            width: calc(100% / 2);
            text-align: center;
        }
        &.active {
            background-color: $endeavour;
            color: white;
        }
    }
}
