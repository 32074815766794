footer {
    background-color: $light-periwinkle;
    color: $bianca-color;
    // height: $footer-height;
    // position: absolute;
    // bottom: 0;
    // width: 100%;
    
    .bottom-wrapper {
        background-color:  $mine-shaft-color;
    }
    .footer-item {
        padding: 20px;
        background-color: $light-periwinkle;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq("lg", max) {
            flex-wrap: wrap;
        }
        @include mq("sm", max) {
            flex-wrap: wrap;
        }
        @include mq-only("md", "lg") {
            flex-wrap: nowrap;
        }
        
        .item-wrapper {
            padding: 5px 45px;
            margin: 10px 0;
            list-style-type: none;
            @include mq("lg", max) {
                padding: 5px 55px;
            }
            @include mq("sm", max) {
                padding: 5px 15px;
            }

            @include mq-only("md", "lg") {
                display: flex;
                flex-direction: column;
                flex: 1;
                padding-left: 20px;
                padding-right: 20px;
            }
            
            .footer-item-title {
                font-weight: 500;
                font-size: 12px;
                letter-spacing: 0.5px;
                padding: 8px 50px 8px 0px;
                span {
                    color: $endeavour;

                }
            }
            .footer-list {
                font-size: 16px;
                font-weight: bold;
                width: 240px;
                margin: 5px 0;
                padding: 0px 50px 0px 0px;
                @include mq-only("md", "lg") {
                    width: auto;
                }
                a {
                    color: $endeavour;

                }
            }
            &.first-item-list {
                @include mq-only("md", "lg") {
                    padding-left: 0;
                    padding-right: 20px;
                }
            }
            &.last-item-list {
                @include mq-only("md", "lg") {
                    padding-left: 20px;
                    padding-right: 0;
                }
            }
            &.border-rl {
                border-right : 3px solid $endeavour;
                border-left : 3px solid $endeavour;
                
                @include mq("md", max) {
                    border-top : 3px solid $endeavour;
                    border-bottom : 3px solid $endeavour;
                    border-right : none;
                    border-left : none;
                    padding-top: 25px;
                    padding-bottom: 15px;
                }

                
            }
        }
    }
    
    .footer-content-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 16px 0;
        
        @include mq("lg", max) {
            justify-content: center;
        }
        
        .footer-text {
            font-size: 14px;
            color: $bianca-color;
            margin: 5px;
            text-align: center;
            @include mq("sm", max) {
                font-size: 13px;            
            }
        }
        .social-icon-wrapper {
            white-space: nowrap;
            a {
                color: white;
                &:hover {
                    text-decoration: none;
                }
                i {
                    font-size: 39px;
                    margin: 5px;
                }
            }
        }
    }   
}
