.zi-positive {
    z-index: 999  !important;
}
.zi-negative {
    z-index: -999  !important;
}
.bold-600 {
    font-weight: 600px !important;
}
.bold-normal {
    font-weight: normal !important;
}
.custom-radio-btn {
    position: relative;
    top: 5px;
    left: -10px;
    transform: scale(1.3);
}