$bianca-color : #fefcfb;
$endeavour: #00579A;
$mine-shaft-color : #2a2a2a;
$light-periwinkle : #cfe0f3;
$scorpion-color: #565656;
$punch-color: #DC3545;
$Eucalyptus-color : #28A745;
$Gallery: #EEEEEE;
$catskill-white-color: #F3F6FA;
$maroon-flush-color: #B52143;
$accent-color:#3b434f;
$tundora-color: #4B4B4B;
$fern-color : #66BB7B;
$crimson-color : #D00D26;
$wild-blue-yonder-color : #7B97B7;
$dusty-gray : #9B9B9B;
$de-york : #86D192;
$dolly-color : #FAFF88;
$brick-red-color : #CC3343;
$Malachite-color : #00E13C;
$orange-pee-color : #FF9B00;
$candy-corn-color : #FCF357;
$Persimmon-color : #FC6355;
$silver : #c0c0c0;



$font-size-global: 13px;
$transitionValue: all 0.3s ease-in-out;
$font-default:	'Proxima Nova';

// bootstrap
$blue: $accent-color;

$font-family-sans-serif : $font-default;

$footer-height: 272px;

$grid-breakpoints: (
  xs: 450px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  custom: 350px
   
) !default;