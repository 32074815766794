@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima/ProximaNovaA-Regular.eot');
    src: url('../fonts/proxima/ProximaNovaA-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/proxima/ProximaNovaA-Regular.woff2') format('woff2'),
        url('../fonts/proxima/ProximaNovaA-Regular.woff') format('woff'),
        url('../fonts/proxima/ProximaNovaA-Regular.ttf') format('truetype'),
        url('../fonts/proxima/ProximaNovaA-Regular.svg#ProximaNovaA-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima/ProximaNovaT-Thin.eot');
    src: url('../fonts/proxima/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/proxima/ProximaNovaT-Thin.woff2') format('woff2'),
        url('../fonts/proxima/ProximaNovaT-Thin.woff') format('woff'),
        url('../fonts/proxima/ProximaNovaT-Thin.ttf') format('truetype'),
        url('../fonts/proxima/ProximaNovaT-Thin.svg#ProximaNovaT-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima/ProximaNovaA-Black.eot');
    src: url('../fonts/proxima/ProximaNovaA-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/proxima/ProximaNovaA-Black.woff2') format('woff2'),
        url('../fonts/proxima/ProximaNovaA-Black.woff') format('woff'),
        url('../fonts/proxima/ProximaNovaA-Black.ttf') format('truetype'),
        url('../fonts/proxima/ProximaNovaA-Black.svg#ProximaNovaA-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima/ProximaNova-Semibold.eot');
    src: url('../fonts/proxima/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/proxima/ProximaNova-Semibold.woff2') format('woff2'),
        url('../fonts/proxima/ProximaNova-Semibold.woff') format('woff'),
        url('../fonts/proxima/ProximaNova-Semibold.ttf') format('truetype'),
        url('../fonts/proxima/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../fonts/proxima/ProximaNovaA-Bold.eot');
    src: url('../fonts/proxima/ProximaNovaA-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/proxima/ProximaNovaA-Bold.woff2') format('woff2'),
        url('../fonts/proxima/ProximaNovaA-Bold.woff') format('woff'),
        url('../fonts/proxima/ProximaNovaA-Bold.ttf') format('truetype'),
        url('../fonts/proxima/ProximaNovaA-Bold.svg#ProximaNovaA-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

